import { useNotificationStore } from "~/store/notifications/notificationStore";

export const useVisiteEtablissementStore = defineStore({
  id: "VisiteEtablissementStore",
  state: () => ({}),
  getters: {},
  actions: {
    // socket actions
    async wsReceiveUpdateVets() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;
        $socketAdherent.on("update-vets", async (payload: any) => {
          useNotificationStore().unshiftNewNotifications([payload]);
        });
      } catch (error) {
        // console.log("error : ", error);
      }
    },

    async wsReceiveNewVets() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on("new-vets", async (payload: any) => {
          useNotificationStore().unshiftNewNotifications([payload]);
        });
      } catch (error) {
        // console.log("error : ", error);
      }
    },
  },
});
