<script setup lang="ts">
import { useVisiteEtablissementStore } from "~/store/planning/vets/vetsStore";
import { useDnsStore } from "~/store/dns/dnsStore";
import { useFormationStore } from "~/store/planning/formation/FormationStore";
import { useSensibilisationStore } from "~/store/planning/sensibilisation/SensibilisationStore";
import { useNotificationStore } from "./store/notifications/notificationStore";
import { useUserStore } from "./store/auth";
import { useRdvSpecialisteStore } from "~/store/rdvSpecialiste/rdvSpecialisteStore";
import "vue3-toastify/dist/index.css";
import 'vue-toastification/dist/index.css';

const { wsReceiveUpdateVets, wsReceiveNewVets } = useVisiteEtablissementStore();
const { wsReceiveNewPaiementDns, wsReceiveNewDeclarationDns } = useDnsStore();
const { wsReceiveNewFormation, wsReceiveUpdateFormation } = useFormationStore();
const { wsReceiveNewSensibilisation, wsReceiveUpdateSensibilisation } =
  useSensibilisationStore();
const { getHeaderNotifications } = useNotificationStore();
const { getItemWithExpiry } = useUserStore();
const { wsReceiveNewRdvSpecialiste } = useRdvSpecialisteStore();

const tokenCookie = useRuntimeConfig().public.cookieName;
const token = getItemWithExpiry(tokenCookie);

const fetchNotifications = async () => {
  if (token) await getHeaderNotifications();
};

onMounted(async () => {
  await fetchNotifications();

  // -----------  adhérent  -----------
  // vets socket
  await wsReceiveUpdateVets();
  await wsReceiveNewVets();

  // dns socket
  await wsReceiveNewDeclarationDns();
  await wsReceiveNewPaiementDns();

  // socket formation
  await wsReceiveNewFormation();
  await wsReceiveUpdateFormation();

  // socket sensibilisation
  await wsReceiveNewSensibilisation();
  await wsReceiveUpdateSensibilisation();

  // -----------  affilie  -----------
  // rdv-specialiste socket
  await wsReceiveNewRdvSpecialiste();
});
</script>
<template>
  <div>
    <NuxtLayout>
      <!-- <NuxtLoadingIndicator :height="5" :duration="3000" :throttle="400" /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>
