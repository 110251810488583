import { defineStore } from "pinia";
import cryptoJS from "crypto-js";
import { useNotificationStore } from "./notifications/notificationStore";

export const useUserStore = defineStore(
  "user",
  () => {
    const config = useRuntimeConfig();
    const secureKey = config.public.secureKey;
    const tokenCookie = config.public.cookieName;
    const userCookie = config.public.userCookieName;
    const rolesCookie = config.public.rolesCookieName;
    const centreCookie = config.public.centreCookieName;
    const dateLogOut = config.public.dateLogOut;
    const router = useRoute();

    const maxAgeInSeconds = 1 * 60 * 60;

    const user = ref(getItemWithExpiry(userCookie));
    const roles = ref(getItemWithExpiry(rolesCookie));
    const token = ref(getItemWithExpiry(tokenCookie));
    const centreConnected = ref(getItemWithExpiry(centreCookie));
    const errors = ref();

    const setToken = (data?: string) => {
      if (data) {
        setItemWithExpiry(tokenCookie, data, maxAgeInSeconds * 1000);
      } else {
        localStorage.removeItem(tokenCookie);
      }
      token.value = data;
    };

    const setCentreConnected = (data?: string) => {
      if (data) {
        setItemWithExpiry(centreCookie, data, maxAgeInSeconds * 1000);
      } else {
        localStorage.removeItem(centreCookie);
      }
      centreConnected.value = data;
    };

    const setUser = (data?: any) => {
      if (data) {
        setItemWithExpiry(
          userCookie,
          JSON.stringify(data),
          maxAgeInSeconds * 1000
        );
      } else {
        localStorage.removeItem(userCookie);
      }
      user.value = data;
    };

    const setRoles = (data?: any) => {
      if (data) {
        setItemWithExpiry(
          rolesCookie,
          JSON.stringify(data),
          maxAgeInSeconds * 1000
        );
      } else {
        localStorage.removeItem(rolesCookie);
      }
      roles.value = data;
    };

    const setErrors = (error: any) => (errors.value = error);

    function setItemWithExpiry(key: any, value: any, ttl: any) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    }

    function getItemWithExpiry(key: any) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    }

    const signIn = async (formData: any) => {
      const $publicApi = useNuxtApp().$publicApi;
      const $ability = useNuxtApp().$ability;
      const initializePrivateApi = useNuxtApp().$initializePrivateApi;
      const updateDecryptedToken = useNuxtApp().$updateDecryptedToken;

      try {
        const res = await $publicApi.post(`/api/bo/auth/login`, formData);
        const encryptedToken = cryptoJS.AES.encrypt(
          res.data.token,
          secureKey
        ).toString();

        if (encryptedToken) {
          setToken(encryptedToken);
          console.log("encrypted token => ", encryptedToken);
          initializePrivateApi(encryptedToken);
          updateDecryptedToken(encryptedToken);
          setCentreConnected(res.data.centreConnected);
          const { roles: userRoles, ...userInfo } = res.data.user;
          console.log(res.data.user);
          setUser(userInfo);
          setItemWithExpiry(
            "customer",
            JSON.stringify(res.data.user),
            maxAgeInSeconds * 1000
          );
          if (res.data.statusCustomer === "centre_principal") {
            setRoles(userRoles);
          }
          if (res.data.statusCustomer === "centre_secondaire") {
            setRoles(res.data.roles);
          }
        }

        const permissions = ref([]);

        const processRoleRules = (roles) => {
          roles.forEach((role) => {
            role.rules.forEach((rule) => {
              if (rule) {
                permissions.value.push({
                  action: "manage",
                  subject: rule.menu.name,
                });
                rule.actions.forEach((actions) => {
                  permissions.value.push({
                    action: "manage",
                    subject: actions.titre,
                  });
                });
              }
            });
          });
        };

        if (res.data.statusCustomer === "centre_principal") {
          processRoleRules(res.data.user.roles);
        } else if (res.data.statusCustomer === "centre_secondaire") {
          console.log("res.data.roles", res.data.roles);

          processRoleRules(res.data.roles);
        }

        let string_permission = JSON.stringify(permissions.value);
        $ability.update(JSON.parse(string_permission));
        // pousser la route vers le dashboard en reload la page
        // window.location.reload();
        await navigateTo("/dashboard", { replace: true });
      } catch (error: any) {
        console.log(error);
        setErrors(error.response.data.message);
      }
    };

    const resetUserData = () => {
      setToken();
      setUser();
      setRoles();
      setCentreConnected();
      localStorage.removeItem("role_utilisateur");
      localStorage.removeItem("customer");
      localStorage.removeItem('id')
      setErrors();
    };

    if (!token.value) {
      resetUserData();
    }

    const signOut = async (formData: any) => {
      const $privateApi = useNuxtApp().$privateApi;
      console.log("private data logout => ", $privateApi);
      

      try {
        await $privateApi.post(`/api/bo/auth/logout`, formData);
        resetUserData();
        // window.location.reload();
        await navigateTo("/", { replace: true });
      } catch (error) {
        setErrors("Une erreur est survenue lors de la déconnexion");
        console.log(error);
      }
    };

    const changecentre = async (form: any) => {
      const $privateApi = useNuxtApp().$privateApi;
      console.log("form", form);
      const resultat = await $privateApi.post("api/bo/auth/changecentre", form);
      const responseCentre = resultat.data;
      if (responseCentre) {
        resetUserData();
        let encryptTkn = cryptoJS.AES.encrypt(
          responseCentre.token,
          secureKey
        ).toString();
        if (encryptTkn) {
          setToken(encryptTkn);
          setCentreConnected(responseCentre.centreConnected);
          setUser(responseCentre.user);
          setItemWithExpiry(
            "customer",
            JSON.stringify(responseCentre.user),
            maxAgeInSeconds * 1000
          );
          setRoles(responseCentre.user.roles);
        }
        window.location.reload();
      }
    };
    return {
      user,
      token,
      roles,
      errors,
      setToken,
      setUser,
      setRoles,
      setCentreConnected,
      setErrors,
      signIn,
      signOut,
      resetUserData,
      getItemWithExpiry,
      setItemWithExpiry,
      changecentre,
    };
  },
  {
    persist: true,
  }
);
