import { useUserStore } from "~/store/auth";
import { ref } from "vue";

let logoutTimer;
let checkTokenInterval;
const expireActivity = 10 * 60 * 1000; // 10 seconds for testing
const expireToken = 5 * 60 * 1000; // 5 minutes
const tokens = ref();

function getItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

function resetLogoutTimer() {
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  logoutTimer = setTimeout(() => {
    const userStore = useUserStore();
    tokens.value = userStore.token;

    const logoutFormData = new FormData();

    if (tokens.value) {
      logoutFormData.append("token", tokens.value);
      userStore.signOut(logoutFormData);
      tokens.value = null;
    }
  }, expireActivity);
}

function startUserActivityListeners() {
  window.addEventListener("mousemove", resetLogoutTimer);
  window.addEventListener("keydown", resetLogoutTimer);
}

function stopUserActivityListeners() {
  window.removeEventListener("mousemove", resetLogoutTimer);
  window.removeEventListener("keydown", resetLogoutTimer);
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  const config = useRuntimeConfig();
  const tokenCookie = config.public.cookieName;
  const token = ref(getItemWithExpiry(tokenCookie));

  if (!token.value) {
    clearInterval(checkTokenInterval);
    stopUserActivityListeners();

    if (/^\/forgot-pwd(\/.*)?$/.test(to?.path) || '/register' === to?.path) {
      return;
    }

    userStore.resetUserData();
    if (to?.path !== "/") {
      abortNavigation();
      return navigateTo("/");
    }
  } else {
    startUserActivityListeners();

    if (to?.path === "/") {
      return navigateTo("/dashboard");
    } else {
      resetLogoutTimer();

      if (!checkTokenInterval) {
        checkTokenInterval = setInterval(() => {
          const currentToken = getItemWithExpiry(tokenCookie);
          if (!currentToken) {
            userStore.resetUserData();
            clearInterval(checkTokenInterval);
            stopUserActivityListeners();
            if (to?.path !== "/") {
              return navigateTo("/");
            }
          }
        }, expireToken);
      }
    }
  }
});
