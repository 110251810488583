import { useNotificationStore } from "~/store/notifications/notificationStore";
import { ADD_FORMATION_EVENT, UPDATE_FORMATION_EVENT } from "./utils";

export const useFormationStore = defineStore({
  id: "FormationStore",
  state: () => ({}),
  actions: {
    // socket actions
    async wsReceiveNewFormation() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on(
          ADD_FORMATION_EVENT.socketName,
          async (payload: any) => {
            useNotificationStore().unshiftNewNotifications([payload]);
          }
        );
      } catch (error) {}
    },

    async wsReceiveUpdateFormation() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on(
          UPDATE_FORMATION_EVENT.socketName,
          async (payload: any) => {
            useNotificationStore().unshiftNewNotifications([payload]);
          }
        );
      } catch (error) {}
    },
  },
});
