import { useNotificationStore } from "~/store/notifications/notificationStore";
import {
  ADD_SENSIBILISATION_EVENT,
  UPDATE_SENSIBILISATION_EVENT,
} from "./utils";

export const useSensibilisationStore = defineStore({
  id: "SensibilisationStore",
  state: () => ({}),
  actions: {
    // socket actions
    async wsReceiveNewSensibilisation() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on(
          ADD_SENSIBILISATION_EVENT.socketName,
          async (payload: any) => {
            useNotificationStore().unshiftNewNotifications([payload]);
          }
        );
      } catch (error) {}
    },

    async wsReceiveUpdateSensibilisation() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on(
          UPDATE_SENSIBILISATION_EVENT.socketName,
          async (payload: any) => {
            useNotificationStore().unshiftNewNotifications([payload]);
          }
        );
      } catch (error) {}
    },
  },
});
