import axios from "axios";
import { useUserStore } from "~/store/auth";
import CryptoJS from "crypto-js";
import { ref, watch } from "vue";

export default defineNuxtPlugin(({ nuxtApp, redirect }) => {
  const userStore = useUserStore();

  const config = useRuntimeConfig();
  const host = config.public.baseURL;
  const secureKey = config.public.secureKey;
  const tokenCookie = config.public.cookieName;
  const token = ref();
  let privateApi;
  const decryptedTokenAll = ref();

  // Décrypter le token
  const decryptToken = (encryptedToken: string): string => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secureKey);
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  };

  const initializePrivateApi = (newToken: any) => {
    token.value = newToken;
    privateApi = axios.create({
      baseURL: host,
      headers: {
        common: {
          "Content-Type": "multipart/form-data",
          // "Content-Type": "application/json",
        },
      },
    });

    privateApi.interceptors.request.use((config) => {
      if (token.value) {
        const decryptedToken = decryptToken(token.value);
        config.headers["Authorization"] = `Bearer ${decryptedToken}`;
      }

      return config;
    });

    privateApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const errorCode = error.response.data.statusCode;
        const handleErrorResponse = (statusCode: any) => {
          navigateTo({
            path: "/error",
            query: {
              statusCode: statusCode,
            },
          });
        };
        // Handle specific error codes
        switch (errorCode) {
          case 403:
            handleErrorResponse(errorCode);
            break;

          default:
            console.error("Unhandled error code:", errorCode);
            break;
        }
        return Promise.reject(error);
      }
    );
  };

  const publicApi = axios.create({
    baseURL: host,
    headers: {
      common: {
        "Content-Type": "application/json",
      },
    },
  });

  // appleler la fonction pour initialiser le token
  initializePrivateApi(userStore.getItemWithExpiry(tokenCookie));

  // function pour mettre à jour le decryptedToken
  const updateDecryptedToken = (newToken: any) => {
    token.value = newToken;
    decryptedTokenAll.value = decryptToken(token.value);
    token.value = userStore.getItemWithExpiry(tokenCookie);
  };

  token.value = userStore.getItemWithExpiry(tokenCookie);

  if (token.value) {
    decryptedTokenAll.value = decryptToken(token.value);
  }

  // Token à décrypter

  return {
    provide: {
      privateApi: privateApi,
      publicApi: publicApi,
      decryptedToken: decryptedTokenAll,
      initializePrivateApi: initializePrivateApi,
      updateDecryptedToken: updateDecryptedToken,
    },
  };
});
