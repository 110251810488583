<template>
    <NuxtLayout>
        <div class="container-xxl">
            <template v-if="statusCode == 404">
                <div class="hk-pg-body">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <img src="./assets/images/Ostie_Logo_Color.png" class="img-fluid w-sm-50 w-50 mx-auto"
                                alt="logo" />
                            <h1 class="display-4 fw-bold mb-2 text-center">404</h1>
                            <p class="p-lg">Désolé, la page demandée est introuvable. Essayez de
                                trouver
                                avec un autre nom.</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="statusCode == 403">
                <div class="hk-pg-body">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <img src="./assets/images/Ostie_Logo_Color.png" class="img-fluid w-sm-50 w-50 mx-auto"
                                alt="logo" />
                            <h1 class="display-4 fw-bold mb-2 text-center">403</h1>
                            <p class="p-lg">Désolé, vous n'avez pas accès à cette page.</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="statusCode == 404">
                <div class="hk-pg-body">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <img src="./assets/images/Ostie_Logo_Color.png" class="img-fluid w-sm-50 w-50 mx-auto"
                                alt="logo" />
                            <h1 class="display-4 fw-bold mb-2 text-center">404</h1>
                            <p class="p-lg">Désolé, vous n'avez pas accès à cette page.</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="statusCode == 400">
                <div class="hk-pg-body">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <img src="./assets/images/Ostie_Logo_Color.png" class="img-fluid w-sm-50 w-50 mx-auto"
                                alt="logo" />
                            <h1 class="display-4 fw-bold mb-2 text-center">403</h1>
                            <p class="p-lg">Désolé, Bad Request</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="statusCode == 500">
                <div class="hk-pg-body">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <img src="./assets/images/Ostie_Logo_Color.png" class="img-fluid w-sm-50 w-50 mx-auto"
                                alt="logo" />
                            <h1 class="display-4 fw-bold mb-2 text-center">500</h1>
                            <p class="p-lg">Désolé, Il y a une erreur au serveur</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!statusCode">
                <div class="hk-pg-body">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <img src="./assets/images/Ostie_Logo_Color.png" class="img-fluid w-sm-50 w-50 mx-auto"
                                alt="logo" />
                            <h1 class="display-4 fw-bold mb-2 text-center">404</h1>
                            <p class="p-lg">Désolé, la page demandée est introuvable. Essayez de
                                trouver
                                avec un autre nom.</p>
                        </div>
                    </div>
                </div>


            </template>
            <p class="text-center">
                <NuxtLink to="" @click="goBack" class="btn btn-primary mt-4">Retourner vers l'application</NuxtLink>
            </p>
        </div>
    </NuxtLayout>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
defineProps(['error']);
const statusCode = ref();
const statusMessage = ref();
const error = useError();
console.log('error', error);
console.log(router.currentRoute._value.query);
statusCode.value = router.currentRoute._value.query.statusCode;
statusMessage.value = router.currentRoute._value.query.statusMessage;

const goBack = () => {
    router.back();
    console.log('goBack ' + router.back());
};

</script>

<style lang="scss" scoped></style>