import { useUserStore } from "~/store/auth";
import { io } from "socket.io-client";

export default defineNuxtPlugin(() => {
  const useStore = useUserStore();
  const config = useRuntimeConfig();
  const UserCookie = config.public.userCookieName;

  const data = JSON.parse(useStore.getItemWithExpiry(UserCookie));

  // console.log("useStore.getItemWithExpiry(UserCookie) : ", data);

  const session_user = data ? data._id : null;
  const menuTag = data ? (data.menuTag ? data.menuTag : []) : [];

  console.log("session_user : ", session_user);

  // const menuTag = ["gestion-service-sst"];
  // const menuTag = [
  //   "",
  //   "gestion-contenu",
  //   "",
  //   "gestion-contenu",
  //   "gestion-adherent",
  //   "gestion-affilie",
  // ];

  if (session_user == null) {
    return {
      provide: {
        socketAdherent: {
          id: null,
          menuTag: [],
        },
        socketAffilies: {
          id: null,
          menuTag: [],
        },
      },
    };
  } else {
    try {
      // var socketAdherent = io("http://localhost:5001", {
      var socketAdherent = io(config.public.affSocketUrl, {
        query: {
          id: session_user,
          menuTag: menuTag,
        },
      });

      // var socketAffilies = io("http://localhost:5001", {
      var socketAffilies = io(config.public.affSocketUrl, {
        query: {
          id: session_user,
          menuTag: menuTag,
        },
      });
    } catch (error) {}

    return {
      provide: {
        socketAdherent,
        socketAffilies,
      },
    };
  }
});
