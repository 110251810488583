import { default as indexDDpJLqPBhmMeta } from "/app/pages/action/index.vue?macro=true";
import { default as _91id_93FlLm03CzZLMeta } from "/app/pages/adherents/[id].vue?macro=true";
import { default as indexbkX6wuincsMeta } from "/app/pages/adherents/index.vue?macro=true";
import { default as _91id_933RBKFoaHqYMeta } from "/app/pages/adhesion/[id].vue?macro=true";
import { default as _91id_93zAVVOYUmEQMeta } from "/app/pages/adhesion/clotures/[id].vue?macro=true";
import { default as indexGnzHNWwz3ZMeta } from "/app/pages/adhesion/clotures/index.vue?macro=true";
import { default as index8rXZatycdpMeta } from "/app/pages/adhesion/index.vue?macro=true";
import { default as _91id_93OdxkGm08MkMeta } from "/app/pages/adhesion/traitements/[id].vue?macro=true";
import { default as _91id_9350QlC9OmNNMeta } from "/app/pages/adhesion/traitements/files/[id].vue?macro=true";
import { default as index0gBOPTgyXNMeta } from "/app/pages/adhesion/traitements/index.vue?macro=true";
import { default as _91id_93fYSPh2mWKMMeta } from "/app/pages/adhesion/valides/[id].vue?macro=true";
import { default as index03PsXYp8W9Meta } from "/app/pages/adhesion/valides/index.vue?macro=true";
import { default as _91id_930KVdUhpH6VMeta } from "/app/pages/affectation/add/[id].vue?macro=true";
import { default as _91id_93fK3mcHjL2oMeta } from "/app/pages/affectation/edit/[id].vue?macro=true";
import { default as indexzCTtATjEr7Meta } from "/app/pages/affectation/index.vue?macro=true";
import { default as indexMNOwxhSHQZMeta } from "/app/pages/affilies/index.vue?macro=true";
import { default as index62PVvB1lWsMeta } from "/app/pages/avis-affilie/index.vue?macro=true";
import { default as _91id_93hq94yZmZ4dMeta } from "/app/pages/avis-embauche-debauche/[id].vue?macro=true";
import { default as _91id_93a7ZZImUuyWMeta } from "/app/pages/avis-embauche-debauche/historique-ae-ed/[id].vue?macro=true";
import { default as indexWo4LlXD7UsMeta } from "/app/pages/avis-embauche-debauche/historique-ae-ed/index.vue?macro=true";
import { default as indexlYolvEFBntMeta } from "/app/pages/avis-embauche-debauche/index.vue?macro=true";
import { default as index1cFf630yRDMeta } from "/app/pages/avis/index.vue?macro=true";
import { default as indexY9ODxP3l5eMeta } from "/app/pages/bulletin-consultation/index.vue?macro=true";
import { default as _91id_93X3Qj3bIzdZMeta } from "/app/pages/contenus/reglement-adherent/[id].vue?macro=true";
import { default as _91id_93mVnACYD4dEMeta } from "/app/pages/contenus/reglement-adherent/archive-in/[id].vue?macro=true";
import { default as index7omLMih84yMeta } from "/app/pages/contenus/reglement-adherent/archive-in/index.vue?macro=true";
import { default as indexSBXhEh5huLMeta } from "/app/pages/contenus/reglement-adherent/convocations/index.vue?macro=true";
import { default as index2tqyFOCN5aMeta } from "/app/pages/contenus/reglement-adherent/convocations/new/index.vue?macro=true";
import { default as indexVVGhb8rWj8Meta } from "/app/pages/contenus/reglement-adherent/index.vue?macro=true";
import { default as index_40old_41vg71sXIloeMeta } from "/app/pages/contenus/reglement-adherent/index(old).vue?macro=true";
import { default as _91id_93Y1aMBXbu0WMeta } from "/app/pages/contenus/reglement-adherent/poster/[id].vue?macro=true";
import { default as indexhi3IEy62RNMeta } from "/app/pages/contenus/reglement-adherent/poster/index.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91id_93ES9H7ESlMuMeta } from "/app/pages/dns/[id].vue?macro=true";
import { default as fichierFinderLxnncYy5DDMeta } from "/app/pages/dns/fichiers/fichierFinder.vue?macro=true";
import { default as indexwPTjHBhu1LMeta } from "/app/pages/dns/fichiers/index.vue?macro=true";
import { default as indexZkGoeDWHl8Meta } from "/app/pages/dns/index.vue?macro=true";
import { default as indexeVBKvJGJUFMeta } from "/app/pages/dns/paiements/index.vue?macro=true";
import { default as indexsHANlbWIo1Meta } from "/app/pages/dns/relances/index.vue?macro=true";
import { default as index7DWpj8IqIMMeta } from "/app/pages/edition-fdr/articles/create/index.vue?macro=true";
import { default as indexpAxxovZ16OMeta } from "/app/pages/edition-fdr/articles/index.vue?macro=true";
import { default as _91id_935jeapZcaJCMeta } from "/app/pages/edition-fdr/boite-risque/[id].vue?macro=true";
import { default as _91id_93LQ68SWiJdfMeta } from "/app/pages/edition-fdr/boites/[id].vue?macro=true";
import { default as _91id_93GT9WynZdFKMeta } from "/app/pages/edition-fdr/facteur-diagnostique/[id].vue?macro=true";
import { default as _91id_93PEx6wLQONvMeta } from "/app/pages/edition-fdr/facteur-risque/[id].vue?macro=true";
import { default as indexdwogxHYfH4Meta } from "/app/pages/edition-fdr/index.vue?macro=true";
import { default as editions_45fdrTC3tQRMNMIMeta } from "/app/pages/editions-fdr.vue?macro=true";
import { default as indexUh4D9BfWNsMeta } from "/app/pages/fdr/conseils/index.vue?macro=true";
import { default as _91id_934xz2e21JSpMeta } from "/app/pages/fdr/create/boite/[id].vue?macro=true";
import { default as _91idfdr_93iM65pwPyUMMeta } from "/app/pages/fdr/create/diagnostic/[idfdr].vue?macro=true";
import { default as _91id_93qVHDIhOUyvMeta } from "/app/pages/fdr/create/fdr/[id].vue?macro=true";
import { default as _91id_93Ty1hnLpC9XMeta } from "/app/pages/fdr/create/regle/[id].vue?macro=true";
import { default as indexE6FX2tPhldMeta } from "/app/pages/fdr/create/thematique/index.vue?macro=true";
import { default as _91idfdr_93ySZW4SoeNZMeta } from "/app/pages/fdr/diagnostic/[idfdr].vue?macro=true";
import { default as _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta } from "/app/pages/fdr/diagnostic/[idthematique]-[idfdr].vue?macro=true";
import { default as _91iddiagnostic_93lxs4LpnEPqMeta } from "/app/pages/fdr/diagnostic/create/[iddiagnostic].vue?macro=true";
import { default as _91iddiagnostic_93ENU0gYSjjMMeta } from "/app/pages/fdr/diagnostic/resiliation/[iddiagnostic].vue?macro=true";
import { default as indexRpy5j0t1ioMeta } from "/app/pages/fdr/fdr/index.vue?macro=true";
import { default as _91id_93HjHsVP3n7cMeta } from "/app/pages/fdr/point/boite/[id].vue?macro=true";
import { default as _91idboite_93iBQyrYsHQIMeta } from "/app/pages/fdr/point/point/[idboite].vue?macro=true";
import { default as index8TXKUfDr9sMeta } from "/app/pages/fdr/point/thematique/index.vue?macro=true";
import { default as _91id_9322LatRXwFTMeta } from "/app/pages/fdr/read/boite/[id].vue?macro=true";
import { default as _91idfdr_93ZQLhLcHsq5Meta } from "/app/pages/fdr/read/diagnostic/[idfdr].vue?macro=true";
import { default as _91id_93E6VDNx88BHMeta } from "/app/pages/fdr/read/fdr/[id].vue?macro=true";
import { default as _91iddiag_93i3sBuiXA7hMeta } from "/app/pages/fdr/read/regle/[iddiag].vue?macro=true";
import { default as _91id_93TxElgQ2VeaMeta } from "/app/pages/fdr/regle/[id].vue?macro=true";
import { default as indexDHNDcclCD4Meta } from "/app/pages/fdr/simulation/index.vue?macro=true";
import { default as _91idthematique_93sWconIEA1bMeta } from "/app/pages/fdr/tableau/[idthematique].vue?macro=true";
import { default as _91id_93o18MsErAtEMeta } from "/app/pages/fdr/thematiques/[id].vue?macro=true";
import { default as fiches_45conseil0I2A9JOziLMeta } from "/app/pages/fiches-conseil.vue?macro=true";
import { default as indexIg9GPKq5r8Meta } from "/app/pages/file/index.vue?macro=true";
import { default as _91id_93OjTdWVcZIlMeta } from "/app/pages/forgot-pwd/[id].vue?macro=true";
import { default as index_backup7feLI426f9Meta } from "/app/pages/forgot-pwd/index_backup.vue?macro=true";
import { default as indexCNvftuSas5Meta } from "/app/pages/forgot-pwd/index.vue?macro=true";
import { default as formationsF6ti9mewdHMeta } from "/app/pages/formations.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexKRS6vUqP7tMeta } from "/app/pages/liste-rdv/index.vue?macro=true";
import { default as _91id_93za2Ux7Dw60Meta } from "/app/pages/menu/[id].vue?macro=true";
import { default as index2zc2jUiOUrMeta } from "/app/pages/menu/add/index.vue?macro=true";
import { default as indexKEaHWmyZdKMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as _91id_93viLy1osCf5Meta } from "/app/pages/modules/[id].vue?macro=true";
import { default as indexuahQCjcb2xMeta } from "/app/pages/modules/add/index.vue?macro=true";
import { default as indexhAKAs5PIqoMeta } from "/app/pages/modules/index.vue?macro=true";
import { default as indexj88iZwExVdMeta } from "/app/pages/notifications/index.vue?macro=true";
import { default as _91id_93Zj0lQwCKVZMeta } from "/app/pages/planning/accompagnement-appuie/[id].vue?macro=true";
import { default as index1RisrHifuKMeta } from "/app/pages/planning/accompagnement-appuie/index.vue?macro=true";
import { default as _91id_93HY2dcbcJYHMeta } from "/app/pages/planning/autres-visites/[id].vue?macro=true";
import { default as indexKh1j3fWnnAMeta } from "/app/pages/planning/autres-visites/index.vue?macro=true";
import { default as _91id_932zmihTN8oPMeta } from "/app/pages/planning/conge/[id].vue?macro=true";
import { default as indexGhmEDIEn5EMeta } from "/app/pages/planning/conge/index.vue?macro=true";
import { default as index25iGYkOZUaMeta } from "/app/pages/planning/formations/index.vue?macro=true";
import { default as _91id_93k6eR2xkDuzMeta } from "/app/pages/planning/garde/[id].vue?macro=true";
import { default as indexdv5Q16lOlHMeta } from "/app/pages/planning/garde/index.vue?macro=true";
import { default as _91id_93PScfK3fDECMeta } from "/app/pages/planning/persuasion/[id].vue?macro=true";
import { default as indexre7SoZz2uOMeta } from "/app/pages/planning/persuasion/index.vue?macro=true";
import { default as index8SeMq53kA8Meta } from "/app/pages/planning/sensibilisation/index.vue?macro=true";
import { default as indexsWADdbNUpgMeta } from "/app/pages/planning/vembauche/index.vue?macro=true";
import { default as _91id_93c4f4MKGQnGMeta } from "/app/pages/planning/vembauche/traiter/[id].vue?macro=true";
import { default as indexdRqujax1qyMeta } from "/app/pages/planning/vembauche/traiter/index.vue?macro=true";
import { default as indexz7VzH5MZOTMeta } from "/app/pages/planning/vets/index.vue?macro=true";
import { default as indexqiRlVmAypGMeta } from "/app/pages/planning/vmo/index.vue?macro=true";
import { default as indextgEnyypUFaMeta } from "/app/pages/profil/index.vue?macro=true";
import { default as indexoXx1CxXh3MMeta } from "/app/pages/rdv-specialist/index.vue?macro=true";
import { default as _91id_93KQajisX2MpMeta } from "/app/pages/reclamations/[id].vue?macro=true";
import { default as indexCirOZi8xQyMeta } from "/app/pages/reclamations/affilie/index.vue?macro=true";
import { default as _91id_931gBFj4oYGVMeta } from "/app/pages/reclamations/archive-in/[id].vue?macro=true";
import { default as index2hHgFWWEMIMeta } from "/app/pages/reclamations/archive-in/index.vue?macro=true";
import { default as indexfo9BAKQ7YOMeta } from "/app/pages/reclamations/index.vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as reglement_45adherentAfPf6BPl40Meta } from "/app/pages/reglement-adherent.vue?macro=true";
import { default as reglement_45interieur_45ostiehfIXlmbsAPMeta } from "/app/pages/reglement-interieur-ostie.vue?macro=true";
import { default as _91id_93q87PjBVG7xMeta } from "/app/pages/roles/[id].vue?macro=true";
import { default as indexzrTxjiQ8pGMeta } from "/app/pages/roles/add/index.vue?macro=true";
import { default as indexd33eSnEIJHMeta } from "/app/pages/roles/index.vue?macro=true";
import { default as securite9DEIbSVdZSMeta } from "/app/pages/securite.vue?macro=true";
import { default as index6BFMIvOGYXMeta } from "/app/pages/suggestions-affilie/index.vue?macro=true";
import { default as suggestionsuP0heK2rmqMeta } from "/app/pages/suggestions.vue?macro=true";
import { default as tableau_45bord_45reclamationsoPpKDjsrfFMeta } from "/app/pages/tableau-bord-reclamations.vue?macro=true";
import { default as _91id_93vDGLzaLEjmMeta } from "/app/pages/user-adherents/[id].vue?macro=true";
import { default as index66ZCqzKtV7Meta } from "/app/pages/user-adherents/index.vue?macro=true";
import { default as _91id_93nDQ2NKWll2Meta } from "/app/pages/users/[id].vue?macro=true";
import { default as _91id_93yLvL1V6HfdMeta } from "/app/pages/users/assigner/active/[id].vue?macro=true";
import { default as indexYySVLk13nRMeta } from "/app/pages/users/assigner/index.vue?macro=true";
import { default as passwordChangeNwRx5tg3vwMeta } from "/app/pages/users/component/passwordChange.vue?macro=true";
import { default as indexG1HlFdJ9R4Meta } from "/app/pages/users/create/index.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "action",
    path: "/action",
    meta: indexDDpJLqPBhmMeta || {},
    component: () => import("/app/pages/action/index.vue").then(m => m.default || m)
  },
  {
    name: "adherents-id",
    path: "/adherents/:id()",
    meta: _91id_93FlLm03CzZLMeta || {},
    component: () => import("/app/pages/adherents/[id].vue").then(m => m.default || m)
  },
  {
    name: "adherents",
    path: "/adherents",
    meta: indexbkX6wuincsMeta || {},
    component: () => import("/app/pages/adherents/index.vue").then(m => m.default || m)
  },
  {
    name: "adhesion-id",
    path: "/adhesion/:id()",
    meta: _91id_933RBKFoaHqYMeta || {},
    component: () => import("/app/pages/adhesion/[id].vue").then(m => m.default || m)
  },
  {
    name: "adhesion-clotures-id",
    path: "/adhesion/clotures/:id()",
    meta: _91id_93zAVVOYUmEQMeta || {},
    component: () => import("/app/pages/adhesion/clotures/[id].vue").then(m => m.default || m)
  },
  {
    name: "adhesion-clotures",
    path: "/adhesion/clotures",
    meta: indexGnzHNWwz3ZMeta || {},
    component: () => import("/app/pages/adhesion/clotures/index.vue").then(m => m.default || m)
  },
  {
    name: "adhesion",
    path: "/adhesion",
    meta: index8rXZatycdpMeta || {},
    component: () => import("/app/pages/adhesion/index.vue").then(m => m.default || m)
  },
  {
    name: "adhesion-traitements-id",
    path: "/adhesion/traitements/:id()",
    meta: _91id_93OdxkGm08MkMeta || {},
    component: () => import("/app/pages/adhesion/traitements/[id].vue").then(m => m.default || m)
  },
  {
    name: "adhesion-traitements-files-id",
    path: "/adhesion/traitements/files/:id()",
    meta: _91id_9350QlC9OmNNMeta || {},
    component: () => import("/app/pages/adhesion/traitements/files/[id].vue").then(m => m.default || m)
  },
  {
    name: "adhesion-traitements",
    path: "/adhesion/traitements",
    meta: index0gBOPTgyXNMeta || {},
    component: () => import("/app/pages/adhesion/traitements/index.vue").then(m => m.default || m)
  },
  {
    name: "adhesion-valides-id",
    path: "/adhesion/valides/:id()",
    meta: _91id_93fYSPh2mWKMMeta || {},
    component: () => import("/app/pages/adhesion/valides/[id].vue").then(m => m.default || m)
  },
  {
    name: "adhesion-valides",
    path: "/adhesion/valides",
    meta: index03PsXYp8W9Meta || {},
    component: () => import("/app/pages/adhesion/valides/index.vue").then(m => m.default || m)
  },
  {
    name: "affectation-add-id",
    path: "/affectation/add/:id()",
    component: () => import("/app/pages/affectation/add/[id].vue").then(m => m.default || m)
  },
  {
    name: "affectation-edit-id",
    path: "/affectation/edit/:id()",
    meta: _91id_93fK3mcHjL2oMeta || {},
    component: () => import("/app/pages/affectation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "affectation",
    path: "/affectation",
    component: () => import("/app/pages/affectation/index.vue").then(m => m.default || m)
  },
  {
    name: "affilies",
    path: "/affilies",
    meta: indexMNOwxhSHQZMeta || {},
    component: () => import("/app/pages/affilies/index.vue").then(m => m.default || m)
  },
  {
    name: "avis-affilie",
    path: "/avis-affilie",
    meta: index62PVvB1lWsMeta || {},
    component: () => import("/app/pages/avis-affilie/index.vue").then(m => m.default || m)
  },
  {
    name: "avis-embauche-debauche-id",
    path: "/avis-embauche-debauche/:id()",
    meta: _91id_93hq94yZmZ4dMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/[id].vue").then(m => m.default || m)
  },
  {
    name: "avis-embauche-debauche-historique-ae-ed-id",
    path: "/avis-embauche-debauche/historique-ae-ed/:id()",
    meta: _91id_93a7ZZImUuyWMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/historique-ae-ed/[id].vue").then(m => m.default || m)
  },
  {
    name: "avis-embauche-debauche-historique-ae-ed",
    path: "/avis-embauche-debauche/historique-ae-ed",
    meta: indexWo4LlXD7UsMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/historique-ae-ed/index.vue").then(m => m.default || m)
  },
  {
    name: "avis-embauche-debauche",
    path: "/avis-embauche-debauche",
    meta: indexlYolvEFBntMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/index.vue").then(m => m.default || m)
  },
  {
    name: "avis",
    path: "/avis",
    meta: index1cFf630yRDMeta || {},
    component: () => import("/app/pages/avis/index.vue").then(m => m.default || m)
  },
  {
    name: "bulletin-consultation",
    path: "/bulletin-consultation",
    meta: indexY9ODxP3l5eMeta || {},
    component: () => import("/app/pages/bulletin-consultation/index.vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-id",
    path: "/contenus/reglement-adherent/:id()",
    meta: _91id_93X3Qj3bIzdZMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/[id].vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-archive-in-id",
    path: "/contenus/reglement-adherent/archive-in/:id()",
    meta: _91id_93mVnACYD4dEMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/archive-in/[id].vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-archive-in",
    path: "/contenus/reglement-adherent/archive-in",
    meta: index7omLMih84yMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/archive-in/index.vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-convocations",
    path: "/contenus/reglement-adherent/convocations",
    meta: indexSBXhEh5huLMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/convocations/index.vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-convocations-new",
    path: "/contenus/reglement-adherent/convocations/new",
    meta: index2tqyFOCN5aMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/convocations/new/index.vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent",
    path: "/contenus/reglement-adherent",
    meta: indexVVGhb8rWj8Meta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/index.vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-index(old)",
    path: "/contenus/reglement-adherent/index(old)",
    meta: index_40old_41vg71sXIloeMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/index(old).vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-poster-id",
    path: "/contenus/reglement-adherent/poster/:id()",
    meta: _91id_93Y1aMBXbu0WMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/poster/[id].vue").then(m => m.default || m)
  },
  {
    name: "contenus-reglement-adherent-poster",
    path: "/contenus/reglement-adherent/poster",
    meta: indexhi3IEy62RNMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/poster/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "dns-id",
    path: "/dns/:id()",
    meta: _91id_93ES9H7ESlMuMeta || {},
    component: () => import("/app/pages/dns/[id].vue").then(m => m.default || m)
  },
  {
    name: "dns-fichiers-fichierFinder",
    path: "/dns/fichiers/fichierFinder",
    component: () => import("/app/pages/dns/fichiers/fichierFinder.vue").then(m => m.default || m)
  },
  {
    name: "dns-fichiers",
    path: "/dns/fichiers",
    meta: indexwPTjHBhu1LMeta || {},
    component: () => import("/app/pages/dns/fichiers/index.vue").then(m => m.default || m)
  },
  {
    name: "dns",
    path: "/dns",
    meta: indexZkGoeDWHl8Meta || {},
    component: () => import("/app/pages/dns/index.vue").then(m => m.default || m)
  },
  {
    name: "dns-paiements",
    path: "/dns/paiements",
    meta: indexeVBKvJGJUFMeta || {},
    component: () => import("/app/pages/dns/paiements/index.vue").then(m => m.default || m)
  },
  {
    name: "dns-relances",
    path: "/dns/relances",
    meta: indexsHANlbWIo1Meta || {},
    component: () => import("/app/pages/dns/relances/index.vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr-articles-create",
    path: "/edition-fdr/articles/create",
    meta: index7DWpj8IqIMMeta || {},
    component: () => import("/app/pages/edition-fdr/articles/create/index.vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr-articles",
    path: "/edition-fdr/articles",
    meta: indexpAxxovZ16OMeta || {},
    component: () => import("/app/pages/edition-fdr/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr-boite-risque-id",
    path: "/edition-fdr/boite-risque/:id()",
    meta: _91id_935jeapZcaJCMeta || {},
    component: () => import("/app/pages/edition-fdr/boite-risque/[id].vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr-boites-id",
    path: "/edition-fdr/boites/:id()",
    meta: _91id_93LQ68SWiJdfMeta || {},
    component: () => import("/app/pages/edition-fdr/boites/[id].vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr-facteur-diagnostique-id",
    path: "/edition-fdr/facteur-diagnostique/:id()",
    meta: _91id_93GT9WynZdFKMeta || {},
    component: () => import("/app/pages/edition-fdr/facteur-diagnostique/[id].vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr-facteur-risque-id",
    path: "/edition-fdr/facteur-risque/:id()",
    meta: _91id_93PEx6wLQONvMeta || {},
    component: () => import("/app/pages/edition-fdr/facteur-risque/[id].vue").then(m => m.default || m)
  },
  {
    name: "edition-fdr",
    path: "/edition-fdr",
    meta: indexdwogxHYfH4Meta || {},
    component: () => import("/app/pages/edition-fdr/index.vue").then(m => m.default || m)
  },
  {
    name: "editions-fdr",
    path: "/editions-fdr",
    meta: editions_45fdrTC3tQRMNMIMeta || {},
    component: () => import("/app/pages/editions-fdr.vue").then(m => m.default || m)
  },
  {
    name: "fdr-conseils",
    path: "/fdr/conseils",
    meta: indexUh4D9BfWNsMeta || {},
    component: () => import("/app/pages/fdr/conseils/index.vue").then(m => m.default || m)
  },
  {
    name: "fdr-create-boite-id",
    path: "/fdr/create/boite/:id()",
    meta: _91id_934xz2e21JSpMeta || {},
    component: () => import("/app/pages/fdr/create/boite/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-create-diagnostic-idfdr",
    path: "/fdr/create/diagnostic/:idfdr()",
    meta: _91idfdr_93iM65pwPyUMMeta || {},
    component: () => import("/app/pages/fdr/create/diagnostic/[idfdr].vue").then(m => m.default || m)
  },
  {
    name: "fdr-create-fdr-id",
    path: "/fdr/create/fdr/:id()",
    meta: _91id_93qVHDIhOUyvMeta || {},
    component: () => import("/app/pages/fdr/create/fdr/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-create-regle-id",
    path: "/fdr/create/regle/:id()",
    meta: _91id_93Ty1hnLpC9XMeta || {},
    component: () => import("/app/pages/fdr/create/regle/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-create-thematique",
    path: "/fdr/create/thematique",
    meta: indexE6FX2tPhldMeta || {},
    component: () => import("/app/pages/fdr/create/thematique/index.vue").then(m => m.default || m)
  },
  {
    name: "fdr-diagnostic-idfdr",
    path: "/fdr/diagnostic/:idfdr()",
    meta: _91idfdr_93ySZW4SoeNZMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/[idfdr].vue").then(m => m.default || m)
  },
  {
    name: "fdr-diagnostic-idthematique-idfdr",
    path: "/fdr/diagnostic/:idthematique()-:idfdr()",
    meta: _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/[idthematique]-[idfdr].vue").then(m => m.default || m)
  },
  {
    name: "fdr-diagnostic-create-iddiagnostic",
    path: "/fdr/diagnostic/create/:iddiagnostic()",
    meta: _91iddiagnostic_93lxs4LpnEPqMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/create/[iddiagnostic].vue").then(m => m.default || m)
  },
  {
    name: "fdr-diagnostic-resiliation-iddiagnostic",
    path: "/fdr/diagnostic/resiliation/:iddiagnostic()",
    meta: _91iddiagnostic_93ENU0gYSjjMMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/resiliation/[iddiagnostic].vue").then(m => m.default || m)
  },
  {
    name: "fdr-fdr",
    path: "/fdr/fdr",
    meta: indexRpy5j0t1ioMeta || {},
    component: () => import("/app/pages/fdr/fdr/index.vue").then(m => m.default || m)
  },
  {
    name: "fdr-point-boite-id",
    path: "/fdr/point/boite/:id()",
    meta: _91id_93HjHsVP3n7cMeta || {},
    component: () => import("/app/pages/fdr/point/boite/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-point-point-idboite",
    path: "/fdr/point/point/:idboite()",
    meta: _91idboite_93iBQyrYsHQIMeta || {},
    component: () => import("/app/pages/fdr/point/point/[idboite].vue").then(m => m.default || m)
  },
  {
    name: "fdr-point-thematique",
    path: "/fdr/point/thematique",
    meta: index8TXKUfDr9sMeta || {},
    component: () => import("/app/pages/fdr/point/thematique/index.vue").then(m => m.default || m)
  },
  {
    name: "fdr-read-boite-id",
    path: "/fdr/read/boite/:id()",
    meta: _91id_9322LatRXwFTMeta || {},
    component: () => import("/app/pages/fdr/read/boite/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-read-diagnostic-idfdr",
    path: "/fdr/read/diagnostic/:idfdr()",
    meta: _91idfdr_93ZQLhLcHsq5Meta || {},
    component: () => import("/app/pages/fdr/read/diagnostic/[idfdr].vue").then(m => m.default || m)
  },
  {
    name: "fdr-read-fdr-id",
    path: "/fdr/read/fdr/:id()",
    meta: _91id_93E6VDNx88BHMeta || {},
    component: () => import("/app/pages/fdr/read/fdr/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-read-regle-iddiag",
    path: "/fdr/read/regle/:iddiag()",
    meta: _91iddiag_93i3sBuiXA7hMeta || {},
    component: () => import("/app/pages/fdr/read/regle/[iddiag].vue").then(m => m.default || m)
  },
  {
    name: "fdr-regle-id",
    path: "/fdr/regle/:id()",
    meta: _91id_93TxElgQ2VeaMeta || {},
    component: () => import("/app/pages/fdr/regle/[id].vue").then(m => m.default || m)
  },
  {
    name: "fdr-simulation",
    path: "/fdr/simulation",
    meta: indexDHNDcclCD4Meta || {},
    component: () => import("/app/pages/fdr/simulation/index.vue").then(m => m.default || m)
  },
  {
    name: "fdr-tableau-idthematique",
    path: "/fdr/tableau/:idthematique()",
    meta: _91idthematique_93sWconIEA1bMeta || {},
    component: () => import("/app/pages/fdr/tableau/[idthematique].vue").then(m => m.default || m)
  },
  {
    name: "fdr-thematiques-id",
    path: "/fdr/thematiques/:id()",
    meta: _91id_93o18MsErAtEMeta || {},
    component: () => import("/app/pages/fdr/thematiques/[id].vue").then(m => m.default || m)
  },
  {
    name: "fiches-conseil",
    path: "/fiches-conseil",
    component: () => import("/app/pages/fiches-conseil.vue").then(m => m.default || m)
  },
  {
    name: "file",
    path: "/file",
    meta: indexIg9GPKq5r8Meta || {},
    component: () => import("/app/pages/file/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-pwd-id",
    path: "/forgot-pwd/:id()",
    component: () => import("/app/pages/forgot-pwd/[id].vue").then(m => m.default || m)
  },
  {
    name: "forgot-pwd-index_backup",
    path: "/forgot-pwd/index_backup",
    component: () => import("/app/pages/forgot-pwd/index_backup.vue").then(m => m.default || m)
  },
  {
    name: "forgot-pwd",
    path: "/forgot-pwd",
    component: () => import("/app/pages/forgot-pwd/index.vue").then(m => m.default || m)
  },
  {
    name: "formations",
    path: "/formations",
    component: () => import("/app/pages/formations.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "liste-rdv",
    path: "/liste-rdv",
    meta: indexKRS6vUqP7tMeta || {},
    component: () => import("/app/pages/liste-rdv/index.vue").then(m => m.default || m)
  },
  {
    name: "menu-id",
    path: "/menu/:id()",
    meta: _91id_93za2Ux7Dw60Meta || {},
    component: () => import("/app/pages/menu/[id].vue").then(m => m.default || m)
  },
  {
    name: "menu-add",
    path: "/menu/add",
    meta: index2zc2jUiOUrMeta || {},
    component: () => import("/app/pages/menu/add/index.vue").then(m => m.default || m)
  },
  {
    name: "menu",
    path: "/menu",
    meta: indexKEaHWmyZdKMeta || {},
    component: () => import("/app/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "modules-id",
    path: "/modules/:id()",
    meta: _91id_93viLy1osCf5Meta || {},
    component: () => import("/app/pages/modules/[id].vue").then(m => m.default || m)
  },
  {
    name: "modules-add",
    path: "/modules/add",
    meta: indexuahQCjcb2xMeta || {},
    component: () => import("/app/pages/modules/add/index.vue").then(m => m.default || m)
  },
  {
    name: "modules",
    path: "/modules",
    meta: indexhAKAs5PIqoMeta || {},
    component: () => import("/app/pages/modules/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexj88iZwExVdMeta || {},
    component: () => import("/app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-accompagnement-appuie-id",
    path: "/planning/accompagnement-appuie/:id()",
    component: () => import("/app/pages/planning/accompagnement-appuie/[id].vue").then(m => m.default || m)
  },
  {
    name: "planning-accompagnement-appuie",
    path: "/planning/accompagnement-appuie",
    meta: index1RisrHifuKMeta || {},
    component: () => import("/app/pages/planning/accompagnement-appuie/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-autres-visites-id",
    path: "/planning/autres-visites/:id()",
    component: () => import("/app/pages/planning/autres-visites/[id].vue").then(m => m.default || m)
  },
  {
    name: "planning-autres-visites",
    path: "/planning/autres-visites",
    meta: indexKh1j3fWnnAMeta || {},
    component: () => import("/app/pages/planning/autres-visites/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-conge-id",
    path: "/planning/conge/:id()",
    component: () => import("/app/pages/planning/conge/[id].vue").then(m => m.default || m)
  },
  {
    name: "planning-conge",
    path: "/planning/conge",
    meta: indexGhmEDIEn5EMeta || {},
    component: () => import("/app/pages/planning/conge/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-formations",
    path: "/planning/formations",
    meta: index25iGYkOZUaMeta || {},
    component: () => import("/app/pages/planning/formations/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-garde-id",
    path: "/planning/garde/:id()",
    component: () => import("/app/pages/planning/garde/[id].vue").then(m => m.default || m)
  },
  {
    name: "planning-garde",
    path: "/planning/garde",
    meta: indexdv5Q16lOlHMeta || {},
    component: () => import("/app/pages/planning/garde/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-persuasion-id",
    path: "/planning/persuasion/:id()",
    component: () => import("/app/pages/planning/persuasion/[id].vue").then(m => m.default || m)
  },
  {
    name: "planning-persuasion",
    path: "/planning/persuasion",
    meta: indexre7SoZz2uOMeta || {},
    component: () => import("/app/pages/planning/persuasion/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-sensibilisation",
    path: "/planning/sensibilisation",
    meta: index8SeMq53kA8Meta || {},
    component: () => import("/app/pages/planning/sensibilisation/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-vembauche",
    path: "/planning/vembauche",
    meta: indexsWADdbNUpgMeta || {},
    component: () => import("/app/pages/planning/vembauche/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-vembauche-traiter-id",
    path: "/planning/vembauche/traiter/:id()",
    meta: _91id_93c4f4MKGQnGMeta || {},
    component: () => import("/app/pages/planning/vembauche/traiter/[id].vue").then(m => m.default || m)
  },
  {
    name: "planning-vembauche-traiter",
    path: "/planning/vembauche/traiter",
    meta: indexdRqujax1qyMeta || {},
    component: () => import("/app/pages/planning/vembauche/traiter/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-vets",
    path: "/planning/vets",
    meta: indexz7VzH5MZOTMeta || {},
    component: () => import("/app/pages/planning/vets/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-vmo",
    path: "/planning/vmo",
    meta: indexqiRlVmAypGMeta || {},
    component: () => import("/app/pages/planning/vmo/index.vue").then(m => m.default || m)
  },
  {
    name: "profil",
    path: "/profil",
    meta: indextgEnyypUFaMeta || {},
    component: () => import("/app/pages/profil/index.vue").then(m => m.default || m)
  },
  {
    name: "rdv-specialist",
    path: "/rdv-specialist",
    meta: indexoXx1CxXh3MMeta || {},
    component: () => import("/app/pages/rdv-specialist/index.vue").then(m => m.default || m)
  },
  {
    name: "reclamations-id",
    path: "/reclamations/:id()",
    meta: _91id_93KQajisX2MpMeta || {},
    component: () => import("/app/pages/reclamations/[id].vue").then(m => m.default || m)
  },
  {
    name: "reclamations-affilie",
    path: "/reclamations/affilie",
    meta: indexCirOZi8xQyMeta || {},
    component: () => import("/app/pages/reclamations/affilie/index.vue").then(m => m.default || m)
  },
  {
    name: "reclamations-archive-in-id",
    path: "/reclamations/archive-in/:id()",
    meta: _91id_931gBFj4oYGVMeta || {},
    component: () => import("/app/pages/reclamations/archive-in/[id].vue").then(m => m.default || m)
  },
  {
    name: "reclamations-archive-in",
    path: "/reclamations/archive-in",
    meta: index2hHgFWWEMIMeta || {},
    component: () => import("/app/pages/reclamations/archive-in/index.vue").then(m => m.default || m)
  },
  {
    name: "reclamations",
    path: "/reclamations",
    meta: indexfo9BAKQ7YOMeta || {},
    component: () => import("/app/pages/reclamations/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "reglement-adherent",
    path: "/reglement-adherent",
    component: () => import("/app/pages/reglement-adherent.vue").then(m => m.default || m)
  },
  {
    name: "reglement-interieur-ostie",
    path: "/reglement-interieur-ostie",
    component: () => import("/app/pages/reglement-interieur-ostie.vue").then(m => m.default || m)
  },
  {
    name: "roles-id",
    path: "/roles/:id()",
    meta: _91id_93q87PjBVG7xMeta || {},
    component: () => import("/app/pages/roles/[id].vue").then(m => m.default || m)
  },
  {
    name: "roles-add",
    path: "/roles/add",
    meta: indexzrTxjiQ8pGMeta || {},
    component: () => import("/app/pages/roles/add/index.vue").then(m => m.default || m)
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexd33eSnEIJHMeta || {},
    component: () => import("/app/pages/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "securite",
    path: "/securite",
    component: () => import("/app/pages/securite.vue").then(m => m.default || m)
  },
  {
    name: "suggestions-affilie",
    path: "/suggestions-affilie",
    meta: index6BFMIvOGYXMeta || {},
    component: () => import("/app/pages/suggestions-affilie/index.vue").then(m => m.default || m)
  },
  {
    name: "suggestions",
    path: "/suggestions",
    component: () => import("/app/pages/suggestions.vue").then(m => m.default || m)
  },
  {
    name: "tableau-bord-reclamations",
    path: "/tableau-bord-reclamations",
    component: () => import("/app/pages/tableau-bord-reclamations.vue").then(m => m.default || m)
  },
  {
    name: "user-adherents-id",
    path: "/user-adherents/:id()",
    component: () => import("/app/pages/user-adherents/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-adherents",
    path: "/user-adherents",
    meta: index66ZCqzKtV7Meta || {},
    component: () => import("/app/pages/user-adherents/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93nDQ2NKWll2Meta || {},
    component: () => import("/app/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "users-assigner-active-id",
    path: "/users/assigner/active/:id()",
    meta: _91id_93yLvL1V6HfdMeta || {},
    component: () => import("/app/pages/users/assigner/active/[id].vue").then(m => m.default || m)
  },
  {
    name: "users-assigner",
    path: "/users/assigner",
    meta: indexYySVLk13nRMeta || {},
    component: () => import("/app/pages/users/assigner/index.vue").then(m => m.default || m)
  },
  {
    name: "users-component-passwordChange",
    path: "/users/component/passwordChange",
    component: () => import("/app/pages/users/component/passwordChange.vue").then(m => m.default || m)
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: indexG1HlFdJ9R4Meta || {},
    component: () => import("/app/pages/users/create/index.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]