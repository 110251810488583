import { useNotificationStore } from "../notifications/notificationStore";

export const useDnsStore = defineStore({
  id: "DnsStore",
  state: () => ({}),
  actions: {
    // socket actions
    async wsReceiveNewPaiementDns() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on("new-paiement-dns", async (payload: any) => {
          useNotificationStore().unshiftNewNotifications([payload]);
        });
      } catch (error) {
        console.log("error : ", error);
      }
    },
    async wsReceiveNewDeclarationDns() {
      try {
        const $axios: any = useNuxtApp().$privateApi;
        const $socketAdherent: any = useNuxtApp().$socketAdherent;

        $socketAdherent.on("new-decalration-dns", async (payload: any) => {
          useNotificationStore().unshiftNewNotifications([payload]);
        });
      } catch (error) {
        console.log("error : ", error);
      }
    },
  },
});
