import { useNotificationStore } from "../notifications/notificationStore";

export const useRdvSpecialisteStore = defineStore({
  id: "RdvSpecialisteStore",
  state: () => ({}),
  actions: {
    async wsReceiveNewRdvSpecialiste() {
      try {
        const $socketAffilies: any = useNuxtApp().$socketAffilies;

        $socketAffilies.on("new-rdv-specialiste", async (payload: any) => {
          // console.log("payload : ", payload);
          useNotificationStore().unshiftNewNotifications([payload]);
        });
      } catch (error) {}
    },
  },
});
