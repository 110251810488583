import { dataNotif } from "./data";

export interface IPagination {
  page?: number;
  total?: number;
  limit?: number;
  next?: number;
  prev?: number;
}

export const defaultPagination: IPagination = {
  page: undefined,
  total: undefined,
  limit: undefined,
  next: undefined,
  prev: undefined,
};

export interface INotificationCaracteristique {
  _id?: string;
  label: string;
  description: string;
}

// label: "vets";

// export interface INotification {
//   caracteristique: INotificationCaracteristique;
//   createdAt?: Date;
//   label: string;
//   message: string;
//   receiver: { user: string; type: "adherent" | "ostie" };
//   seen: boolean;
//   sender: { user: null; type: "adherent" | "ostie" };
//   updatedAt: Date;
//   __v?: 0;
//   _id?: string;

//   // _id: string;
//   // label: string;
//   // message: string;
//   // caracteristique: INotificationCaracteristique;
//   // date: Date;
//   // createdAt: Date;
//   // seen: boolean;
// }

export interface INotification {
  caracteristique: any;
  createdAt?: Date;
  label: string;
  message: string;
  receiver: { user: string; type: "adherent" | "ostie" };
  seen: boolean;
  globalSeen: boolean;
  sender: { user: null; type: "adherent" | "ostie" };
  updatedAt: Date;
  __v?: 0;
  _id?: string;

  // _id: string;
  // label: string;
  // message: string;
  // caracteristique: INotificationCaracteristique;
  // date: Date;
  // createdAt: Date;
  // seen: boolean;
}

const groupData = (notifications: any[]) => {
  const grouped = notifications.reduce((acc, notification) => {
    // Obtenir la date au format "15 Novembre, 2023"
    const date = new Date(notification.createdAt).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    // Vérifier si la date correspond à la date actuelle
    const isToday =
      date ===
      new Date().toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

    if (isToday) {
      // Vérifier si "Récemment ajoutés" existe déjà dans le tableau `grouped`
      const recentlyAddedGroup = acc.find(
        (item: any) => item.group === "Récemment ajoutés"
      );

      if (recentlyAddedGroup) {
        // Si le groupe "Récemment ajoutés" existe, ajouter les données à ce groupe
        recentlyAddedGroup.notifications.push(notification);
      } else {
        // Sinon, créer un nouveau groupe "Récemment ajoutés" avec les données
        acc.unshift({
          group: "Récemment ajoutés",
          notifications: [notification],
        });
      }
    } else {
      // Vérifier si le groupe existe déjà dans le tableau `grouped`
      const group = acc.find((item: any) => item.group === date);

      // Si le groupe existe, ajouter les données à ce groupe
      if (group) {
        group.notifications.push(notification);
      } else {
        // Sinon, créer un nouveau groupe avec les données
        acc.push({
          group: date,
          notifications: [notification],
        });
      }
    }

    return acc;
  }, []);

  console.log("grouped : ", grouped);

  return grouped;
};

export const useNotificationStore = defineStore({
  id: "NotificationStore",
  state: () => ({
    notifications: {
      // data pour le header-component notification
      //   data: [] as INotification[],
      data: [] as any[],
      pagination: {} as IPagination,

      loading: false,
      errors: null,
      errorMessage: [],
      totalUnSeen: 0 as number,
      moreNotificationLoading: false,

      filters: {
        url: "/api/bo/notification",
        filter: [] as any[],
        sort: {},
        page: 0 as number,
        limit: 20 as number,
      },
    },
    notificationsHeader: {
      // data pour la page notification
      data: [] as INotification[],
      pagination: {} as IPagination,

      loading: false,
      errors: null,
      errorMessage: [],
      totalUnSeen: 0 as number,
      moreNotificationLoading: false,

      filters: {
        url: "/api/bo/notification",
        filter: [] as any[],
        sort: {},
        page: 0 as number,
        limit: 20 as number,
      },
    },
  }),

  getters: {
    // parsedNotifications: (state) => state.notifications,
    // parsedNotificationPage: (state) => state.notificationsHeader,
  },

  actions: {
    async getNotifications() {
      const $axios: any = useNuxtApp().$privateApi;

      try {
        // console.log("notif : ", this.notifications);

        this.notifications.loading = true;
        this.notifications.data = [];

        const query = `?year=${2023}&month=${11}&page=${
          this.notifications.filters.page
        }&limit=${this.notifications.filters.limit}&query=${JSON.stringify(
          this.notifications.filters.filter
        )}`;

        const res = await $axios.get(this.notifications.filters.url + query);

        // console.log("res : ", res);

        this.notifications.data = groupData(res.data.data);
        this.notifications.pagination = res.data.pagination;
        this.notifications.totalUnSeen = res.data.totalUnSeen;
        // this.notifications.pagination = res.data.pagination;
      } catch (error) {
        // console.log("error : ", error);
      } finally {
        this.notifications.loading = false;
      }
    },

    async getMoreNotifications() {
      if (!this.notifications.pagination.next) return;

      const $axios: any = useNuxtApp().$privateApi;

      try {
        this.notifications.moreNotificationLoading = true;
        this.notifications.filters.page += 1;

        const query = `?year=${2023}&month=${11}&page=${
          this.notifications.filters.page
        }&limit=${this.notifications.filters.limit}&query=${JSON.stringify(
          this.notifications.filters.filter
        )}`;

        const res = await $axios.get(this.notifications.filters.url + query);

        // console.log("res : ", res);

        // const parsed = this.pushNewNotifications(res.data.data);

        // this.notifications.data.push(this.pushNewNotifications(res.data.data));
        this.pushNewNotifications(res.data.data);
        this.notifications.pagination = res.data.pagination;
        this.notifications.totalUnSeen = res.data.totalUnSeen;
        // this.notifications.pagination = res.data.pagination;
      } catch (error) {
        // console.log("error : ", error);
      } finally {
        this.notifications.moreNotificationLoading = false;
        // this.notifications.loading = false;
      }
    },

    async getHeaderNotifications() {
      const $axios = useNuxtApp().$privateApi;

      try {
        this.notificationsHeader.loading = true;

        const query = `?year=${2023}&month=${11}&page=${
          this.notificationsHeader.filters.page
        }&limit=${
          this.notificationsHeader.filters.limit
        }&query=${JSON.stringify(this.notifications.filters.filter)}`;

        const res = await $axios.get(
          this.notificationsHeader.filters.url + query
        );

        // console.log("res : ", res);

        this.notificationsHeader.data = res.data.data;
        this.notificationsHeader.pagination = res.data.pagination;
        this.notificationsHeader.totalUnSeen = res.data.totalUnSeen;
      } catch (error) {
        // console.log("error : ", error);
      } finally {
        this.notificationsHeader.loading = false;
      }
    },

    async getMoreNotificationsHeader() {
      if (!this.notificationsHeader.pagination.next) return;

      const $axios: any = useNuxtApp().$privateApi;

      try {
        this.notificationsHeader.moreNotificationLoading = true;
        this.notificationsHeader.filters.page += 1;

        const query = `?year=${2023}&month=${11}&page=${
          this.notificationsHeader.filters.page
        }&limit=${
          this.notificationsHeader.filters.limit
        }&query=${JSON.stringify(this.notificationsHeader.filters.filter)}`;

        const res = await $axios.get(
          this.notificationsHeader.filters.url + query
        );

        // console.log("res : ", res);

        // const parsed = this.pushNewNotifications(res.data.data);

        // this.notifications.data.push(this.pushNewNotifications(res.data.data));
        // this.pushNewNotifications(res.data.data);
        this.notificationsHeader.data.push(...res.data.data);
        this.notificationsHeader.pagination = res.data.pagination;
        this.notificationsHeader.totalUnSeen = res.data.totalUnSeen;
        // this.notifications.pagination = res.data.pagination;
      } catch (error) {
        console.log("error : ", error);
      } finally {
        this.notificationsHeader.moreNotificationLoading = false;
        // this.notifications.loading = false;
      }
    },

    async udpateNotification(id?: string) {
      if (!id) return;

      const $axios = useNuxtApp().$privateApi;

      try {
        const res = await $axios.put(
          `${this.notifications.filters.url}/${id}`,
          {
            seen: true,
          }
        );

        console.log("res : ", res);
      } catch (error) {
        console.log("error : ", error);
      }
    },

    pushNewNotifications(notif: any[]) {
      const grouped = groupData(notif);

      const lastIndex = this.notifications.data.length - 1;

      const rest = grouped.filter((group: any) => {
        if (group.group === this.notifications.data[lastIndex].group) {
          this.notifications.data[lastIndex].notifications.push(
            ...group.notifications
          );

          return false;
        } else return true;
      });

      this.notifications.data.push(...rest);
    },

    unshiftNewNotifications(notif: any[]) {
      const grouped = groupData(notif);
      // console.log("res : ", grouped);

      console.log("init : ", this.notifications);

      if (this.existRecentGroup()) {
        if (this.notifications.data[0].group === "Récemment ajoutés") {
          this.notifications.data[0].notifications.unshift(
            ...grouped[0].notifications
          );
        } else {
          this.notifications.data.unshift(...grouped);
        }
      } else {
        this.notifications.data.unshift(...grouped);
      }

      console.log("this.notifications.data : ", this.notifications.data);
    },
    existRecentGroup() {
      if (
        this.notifications.data[0] &&
        this.notifications.data[0].group &&
        this.notifications.data[0].notifications
      ) {
        return true;
      } else {
        return false;
      }
    },
    reInitNotificationState() {
      this.notifications.filters.page = 0;
      this.notificationsHeader.filters.page = 0;

      this.notifications.filters.filter = [];
      this.notificationsHeader.filters.filter = [];
    },
  },
});

const existRecentGroup = (notifications: any[]) => {};
