import "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import frLocal from "@fullcalendar/core/locales/fr";

FullCalendar.options = {
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
  initialView: "dayGridMonth",
  timeZone: "utc",
  locale: frLocal,
  headerToolbar: {
    left: "prev,next today",
    center: "title",
    right: "dayGridMonth,listWeek",
  },
  height: "parent",
  droppable: false,
  editable: false,
  nowIndicator: true,
  selectable: false,
  selectMirror: false,
  displayEventTime: true,
  eventDisplay: "block",
  eventTimeFormat: {
    hour: "numeric",
    minute: "2-digit",
    meridiem: false,
  }
};

export default defineNuxtPlugin((/* nuxtApp */) => {
  return {
    provide: {
      fullCalendar: () => FullCalendar,
    },
  };
});
